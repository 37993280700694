import { Form, Modal, ModalProps, Select, Space } from 'antd';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useDebounce } from 'react-use';

import { Feature } from '../../../shared/models/features';
import { translationNamespace } from '../../constants/translation-resources';
import { useFindTenantsQuery } from '../../data/queries/tenant-queries';
import { Tenant } from '../../../shared/models/tenant';

enum ReassignAllFromTenantFormField {
  NEW_TENANT_ID = 'newTenantId',
}

export interface ReassignAllFromTenantFormValues {
  [ReassignAllFromTenantFormField.NEW_TENANT_ID]: number;
}

interface ReassignAllFromTenantModalProps extends ModalProps {
  onSubmit?: (values: ReassignAllFromTenantFormValues) => void;
  currentTenant: Tenant;
}

export const ReassignAllFromTenantModal: FC<ReassignAllFromTenantModalProps> = ({
  currentTenant,
  onSubmit,
  onOk,
  okButtonProps,
  ...props
}) => {
  const { t } = useTranslation(translationNamespace);
  const [form] = Form.useForm<ReassignAllFromTenantFormValues>();
  const [tenantsSearchValue, setTenantsSearchValue] = useState<string>();
  const [tenantsDebouncedSearchValue, setTenantsDebouncedSearchValue] = useState<string>();
  const { data: tenantsData, isLoading: tenantsLoading } = useFindTenantsQuery(
    {
      page: 1,
      pageSize: 25,
      searchValue: tenantsDebouncedSearchValue,
      attributes: ['id', 'name'],
    },
    props.open
  );

  const triggerSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    form.submit();
    onOk?.(event);
  };

  const submit = (values: ReassignAllFromTenantFormValues) => {
    onSubmit?.(values);
  };

  const tenantSelectOptions = useMemo(() => {
    return tenantsData?.resources
      .filter(tenant => tenant.id !== currentTenant?.id)
      .map((tenant, index) => (
        <Select.Option key={index} value={tenant.id}>
          {tenant.name}
        </Select.Option>
      ));
  }, [currentTenant, tenantsData]);

  const applyTenantsSearch = (value: string) => {
    setTenantsSearchValue(value);
  };

  useDebounce(
    () => {
      setTenantsDebouncedSearchValue(tenantsSearchValue);
    },
    400,
    [tenantsSearchValue]
  );

  return (
    <Form form={form} layout="inline" requiredMark={false} onFinish={submit}>
      <Modal
        {...props}
        centered
        title={
          <Space size="middle" align="start">
            <StyledExclamationCircleOutlined />
            {t(`${Feature.SHARED}:areYouSure`)}
          </Space>
        }
        okText={t(`${Feature.SHARED}:confirm`)}
        cancelText={t(`${Feature.SHARED}:cancel`)}
        okButtonProps={{ ...okButtonProps, htmlType: 'submit' }}
        onOk={triggerSubmit}
      >
        <StyledParagraph>{t('tenants.single.reassignAllFromTenant.info')}</StyledParagraph>
        <Form.Item
          label={t(`${Feature.SHARED}:tenant.label`)}
          name={ReassignAllFromTenantFormField.NEW_TENANT_ID}
          rules={[{ required: true, type: 'number' }]}
          css={marginStyles}
        >
          <Select
            showSearch
            filterOption={false}
            placeholder={t(`${Feature.SHARED}:tenant.placeholder`)}
            loading={tenantsLoading}
            onSearch={applyTenantsSearch}
          >
            {tenantSelectOptions}
          </Select>
        </Form.Item>
      </Modal>
    </Form>
  );
};

const StyledExclamationCircleOutlined = styled(ExclamationCircleOutlined)`
  font-size: 22px;
  color: ${props => props.theme.shared.confirmationModal.icon.color};
`;

const StyledParagraph = styled.p`
  margin-bottom: 30px;
`;

const marginStyles = css`
  margin-bottom: 15px;
`;
