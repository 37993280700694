import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  Typography,
  Row,
  Select,
  Space,
  message,
} from 'antd';
import { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { translationNamespace } from '../../constants/translation-resources';
import { useFindRolesQuery } from '../../data/queries/role-queries';
import { useFindTenantsQuery } from '../../data/queries/tenant-queries';
import { Feature } from '../../../shared/models/features';
import { User } from '../../../shared/models/user';
import { Role } from '../../../shared/models/role';
import {
  noDuplicatesRule,
  redundantSpacebarsRule,
  userPasswordRulesWithoutUsernameRule,
  userPasswordNewUsernameRule,
} from '../../../shared/utils/form-rules';
import { showOnlyOneError } from '../../../shared/styles/form-styles';
import { userSelector } from '../../../shared/data/store/selectors/auth-selectors';
import { hasScopeGroup } from '../../utils/user';
import { useGetDefaultRolesQuery } from '../../../core/data/queries/core-queries';
import { AppResource } from '../../../shared/models/app-resource';
import { CrudOperation } from '../../../shared/models/crud-operation';
import { DefaultRoleKey } from '../../../core/models/default-role';
import { Tenant } from '../../../shared/models/tenant';

export enum UserUpsertFormField {
  EMAIL = 'email',
  USERNAME = 'username',
  PASSWORD = 'password',
  FIRST_NAME = 'firstName',
  MIDDLE_NAME = 'middleName',
  LAST_NAME = 'lastName',
  MOBILE_PHONE = 'mobilePhone',
  DESCRIPTION = 'description',
  TENANTS = 'tenants',
  ROLES = 'roles',
  DEFAULT_ROLES = 'defaultRoles',
}

export interface UserUpsertFormValues {
  [UserUpsertFormField.EMAIL]: string;
  [UserUpsertFormField.USERNAME]: string;
  [UserUpsertFormField.PASSWORD]?: string;
  [UserUpsertFormField.FIRST_NAME]?: string;
  [UserUpsertFormField.MIDDLE_NAME]?: string;
  [UserUpsertFormField.LAST_NAME]?: string;
  [UserUpsertFormField.DESCRIPTION]?: string;
  [UserUpsertFormField.MOBILE_PHONE]?: string;
  [UserUpsertFormField.TENANTS]?: number[];
  [UserUpsertFormField.ROLES]?: number[];
  [UserUpsertFormField.DEFAULT_ROLES]?: number[];
}

interface Props {
  form: FormInstance<UserUpsertFormValues>;
  onSubmit?: (values: UserUpsertFormValues) => void;
  user?: User;
  preselectedTenant?: number | undefined;
}

const { Text } = Typography;

export const UserUpsertForm: FC<Props> = ({ form, onSubmit, user, preselectedTenant }) => {
  const { t } = useTranslation(translationNamespace);
  const currentUser = useSelector(userSelector);
  const [rolesSearchValue, setRolesSearchValue] = useState<string>();
  const [rolesDebouncedSearchValue, setRolesDebouncedSearchValue] = useState<string>();
  const { data: rolesData, isLoading: rolesLoading } = useFindRolesQuery({
    page: 1,
    pageSize: 25,
    searchValue: rolesDebouncedSearchValue,
    attributes: ['id', 'name'],
  });
  const { data: currentTenantData, isLoading: currentTenantLoading } = useFindTenantsQuery(
    {
      page: 1,
      pageSize: 1,
      attributes: ['id', 'name'],
      filters: { id: [preselectedTenant] },
    },
    preselectedTenant !== undefined
  );
  const [tenantsSearchValue, setTenantsSearchValue] = useState<string>();
  const [tenantsDebouncedSearchValue, setTenantsDebouncedSearchValue] = useState<string>();
  const { data: tenantsData, isLoading: tenantsLoading } = useFindTenantsQuery({
    page: 1,
    pageSize: 25,
    searchValue: tenantsDebouncedSearchValue,
    attributes: ['id', 'name'],
  });
  const { data: defaultRoles, isLoading: isDefaultRolesLoading } = useGetDefaultRolesQuery();

  const roleSelectOptions = useMemo(() => {
    let options: Role[] = [];

    if (user?.roles?.length > 0 && !rolesDebouncedSearchValue) {
      options = user.roles;

      if (rolesData?.resources?.length > 0) {
        options = options.concat(
          rolesData.resources.filter(role => !user.roles.some(userRole => userRole.id === role.id))
        );
      }
    } else {
      options = rolesData?.resources;
    }

    return options
      ?.filter(role => !role.isDefault)
      .map(role => {
        return (
          <Select.Option key={role.id} value={role.id}>
            {role.name}
          </Select.Option>
        );
      });
  }, [rolesData?.resources, rolesDebouncedSearchValue, user?.roles]);

  const tenantSelectOptions = useMemo(() => {
    let options: Tenant[] = [];

    const currentTenant = currentTenantData?.resources[0];
    if (currentTenant) {
      const tenantExists = tenantsData?.resources?.some(tenant => tenant.id === currentTenant.id);
      if (!tenantExists) {
        tenantsData?.resources.push(currentTenant);
      }
    }

    if (user?.groups?.length > 0 && !tenantsDebouncedSearchValue) {
      options = user.groups;

      if (tenantsData?.resources?.length > 0) {
        options = options.concat(
          tenantsData?.resources.filter(tenant => !user.groups.some(userTenant => userTenant.id === tenant.id))
        );
      }
    } else if (tenantsData?.resources.length > 0) {
      options = tenantsData?.resources;
    }

    return options.map(tenant => {
      return (
        <Select.Option key={tenant.id} value={tenant.id}>
          {tenant.name}
        </Select.Option>
      );
    });
  }, [currentTenantData?.resources, tenantsData?.resources, tenantsDebouncedSearchValue, user?.groups]);

  const defaultRolesColumns = [
    '',
    t(`users.upsert.role.crudOperations.CREATE`),
    t(`users.upsert.role.crudOperations.READ`),
    t(`users.upsert.role.crudOperations.UPDATE`),
    t(`users.upsert.role.crudOperations.DELETE`),
  ];

  const applyRolesSearch = (value: string) => {
    setRolesSearchValue(value);
  };

  function warnIfDangerousRole(appResource: AppResource, crudOperation: CrudOperation) {
    if (
      (appResource === 'USER' && crudOperation === 'UPDATE') ||
      (appResource === 'ROLE' && ['CREATE', 'UPDATE', 'DELETE'].includes(crudOperation))
    ) {
      message.warning(
        t('users.upsert.role.dangerousRoleWarning', {
          appResource: t(`users.upsert.role.defaultRolesNameMap.${appResource}`),
          crudOperation: t(`users.upsert.role.crudOperations.${crudOperation}`),
        })
      );
    }
  }

  useDebounce(
    () => {
      setRolesDebouncedSearchValue(rolesSearchValue);
    },
    400,
    [rolesSearchValue]
  );

  useDebounce(
    () => {
      setTenantsDebouncedSearchValue(tenantsSearchValue);
    },
    400,
    [tenantsSearchValue]
  );

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      layout="horizontal"
      labelCol={{ lg: 4, xl: 4 }}
      wrapperCol={{ lg: 16, xl: 10 }}
      requiredMark={false}
    >
      <Form.Item
        label={t(`${Feature.SHARED}:email.label`)}
        name={UserUpsertFormField.EMAIL}
        rules={[{ required: true, max: 180 }, { type: 'email' }]}
      >
        <Input placeholder={t(`${Feature.SHARED}:email.placeholder`)} />
      </Form.Item>
      <Form.Item
        label={t(`${Feature.SHARED}:username.label`)}
        name={UserUpsertFormField.USERNAME}
        rules={[{ required: true, min: 6, max: 40 }, redundantSpacebarsRule(t)]}
      >
        <Input placeholder={t(`${Feature.SHARED}:username.placeholder`)} />
      </Form.Item>
      {user == null && (
        <Form.Item
          label={t(`${Feature.SHARED}:password.label`)}
          name={UserUpsertFormField.PASSWORD}
          dependencies={[UserUpsertFormField.USERNAME]}
          rules={[
            ...userPasswordRulesWithoutUsernameRule(t),
            userPasswordNewUsernameRule(form, UserUpsertFormField.USERNAME, t),
          ]}
          css={showOnlyOneError}
        >
          <Input.Password placeholder={t(`${Feature.SHARED}:password.placeholder`)} />
        </Form.Item>
      )}
      <Form.Item
        label={t(`${Feature.SHARED}:firstName.label`)}
        name={UserUpsertFormField.FIRST_NAME}
        rules={[{ type: 'string', min: 2, max: 180 }, redundantSpacebarsRule(t)]}
      >
        <Input placeholder={t(`${Feature.SHARED}:firstName.placeholder`)} />
      </Form.Item>
      <Form.Item
        label={t(`${Feature.SHARED}:middleName.label`)}
        name={UserUpsertFormField.MIDDLE_NAME}
        rules={[{ type: 'string', max: 180 }, redundantSpacebarsRule(t)]}
      >
        <Input placeholder={t(`${Feature.SHARED}:middleName.placeholder`)} />
      </Form.Item>
      <Form.Item
        label={t(`${Feature.SHARED}:lastName.label`)}
        name={UserUpsertFormField.LAST_NAME}
        rules={[{ type: 'string', max: 180 }, redundantSpacebarsRule(t)]}
      >
        <Input placeholder={t(`${Feature.SHARED}:lastName.placeholder`)} />
      </Form.Item>

      <Form.Item label={t(`${Feature.SHARED}:description.label`)} name={UserUpsertFormField.DESCRIPTION}>
        <Input.TextArea placeholder={t(`${Feature.SHARED}:description.placeholder`)} />
      </Form.Item>

      <Form.Item
        label={t(`${Feature.SHARED}:mobilePhone.label`)}
        name={UserUpsertFormField.MOBILE_PHONE}
        rules={[{ max: 16 }, redundantSpacebarsRule(t)]}
      >
        <Input placeholder={t(`${Feature.SHARED}:mobilePhone.placeholder`)} />
      </Form.Item>

      <Divider orientation="left">{t(`${Feature.SHARED}:tenant.plural`)}</Divider>
      <Form.List name={UserUpsertFormField.TENANTS}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(field => (
              <Form.Item key={field.key} label={t(`${Feature.SHARED}:tenant.label`)} css={noMarginBottomStyles}>
                <StyledSpace align="baseline">
                  <Form.Item
                    {...field}
                    name={field.name}
                    messageVariables={{ label: t(`${Feature.SHARED}:tenant.label`) }}
                    rules={[
                      { required: hasScopeGroup(currentUser) },
                      noDuplicatesRule(form, UserUpsertFormField.TENANTS, t('roles.upsert.tenant.duplicateWarning')),
                    ]}
                  >
                    <Select
                      showSearch
                      filterOption={false}
                      loading={tenantsLoading || currentTenantLoading}
                      placeholder={t(`${Feature.SHARED}:tenant.placeholder`)}
                      onSearch={(value: string) => setTenantsSearchValue(value)}
                      onSelect={() => setTenantsSearchValue('')}
                      css={fullWidthStyles}
                    >
                      {tenantSelectOptions}
                    </Select>
                  </Form.Item>
                  <MinusCircleOutlined
                    onClick={() => {
                      remove(field.name);
                      setTimeout(() => {
                        form.validateFields();
                      });
                    }}
                  />
                </StyledSpace>
              </Form.Item>
            ))}
            <Form.Item>
              <StyledButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                {t('roles.upsert.tenant.add')}
              </StyledButton>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Divider orientation="left">{t(`users.upsert.role.defaultRole`)}</Divider>
      <StyledFormItem name={UserUpsertFormField.DEFAULT_ROLES}>
        <Checkbox.Group>
          <Row>
            {defaultRolesColumns.map((columnName, index) => {
              return (
                <StyledCol span={index === 0 ? 5 : 4} key={index}>
                  <Text>{columnName}</Text>
                </StyledCol>
              );
            })}
            {!isDefaultRolesLoading &&
              defaultRoles?.map((defaultRole, index) => {
                return Object.entries(defaultRole).map(([key, value]: [DefaultRoleKey, number]) => (
                  <StyledCol span={key === 'name' ? 5 : 4} key={`${index}${key}`}>
                    {key === 'name' ? (
                      <Text>{t(`users.upsert.role.defaultRolesNameMap.${value}`)}</Text>
                    ) : (
                      value !== -1 && (
                        <Checkbox onClick={() => warnIfDangerousRole(defaultRole['name'], key)} value={value} />
                      )
                    )}
                  </StyledCol>
                ));
              })}
          </Row>
        </Checkbox.Group>
      </StyledFormItem>

      <Divider orientation="left">{t(`${Feature.SHARED}:role.plural`)}</Divider>
      <Form.List name={UserUpsertFormField.ROLES}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(field => (
              <Form.Item key={field.key} label={t(`${Feature.SHARED}:role.label`)} css={noMarginBottomStyles}>
                <StyledSpace align="baseline">
                  <Form.Item
                    {...field}
                    name={field.name}
                    messageVariables={{ label: t(`${Feature.SHARED}:role.label`) }}
                    rules={[
                      { required: true },
                      noDuplicatesRule(form, UserUpsertFormField.ROLES, t('users.upsert.role.duplicateWarning')),
                    ]}
                    css={fullWidthStyles}
                  >
                    <Select
                      showSearch
                      filterOption={false}
                      loading={rolesLoading}
                      onSearch={applyRolesSearch}
                      onSelect={() => setRolesDebouncedSearchValue('')}
                      css={fullWidthStyles}
                    >
                      {roleSelectOptions}
                    </Select>
                  </Form.Item>
                  <MinusCircleOutlined
                    onClick={() => {
                      remove(field.name);
                      setTimeout(() => {
                        form.validateFields();
                      });
                    }}
                  />
                </StyledSpace>
              </Form.Item>
            ))}
            <Form.Item>
              <StyledButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                {t('users.upsert.role.add')}
              </StyledButton>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
};

const noMarginBottomStyles = css`
  margin-bottom: 0;
`;

const fullWidthStyles = css`
  width: 100%;
`;

const StyledSpace = styled(Space)`
  width: 100%;

  > div:not(:last-of-type) {
    width: 95%;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 180px;
`;

const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
  height: 30px;
  border-bottom: 1px solid #d9d9d9;
`;

const StyledFormItem = styled(Form.Item)`
  .ant-col.ant-form-item-control {
    min-width: 570px;
  }
  .ant-row.ant-form-item-row {
    display: flex;
    margin-left: 15%;
  }
`;
