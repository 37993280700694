import axios from 'axios';
import {
  FindAllBatchesResponse,
  FindAllBatchesRequest,
  FindAllDocumentsRequest,
  FindAllDocumentsResponse,
  GetFilterQueryParams,
  GetFilterResponse,
  DocumentActionRequest,
  DocumentActionResponse,
  Reason,
  GetReasonsRequest,
  BatchesActionRequest,
  BatchesActionResponse,
  SetFixedDateRequest,
  SetFixedDateResponse,
} from '@pccr-ifc/pccr-ifc';

import { Config } from '../../../shared/models/config';
import { useService } from '../../../shared/hooks/use-service';

function createApprovalService(config: Config) {
  const BASE_PATH = '/approval';

  const findBatches = (request: FindAllBatchesRequest) =>
    axios.post<FindAllBatchesResponse>(`${config.API_URL}${BASE_PATH}/batches`, request, {
      withCredentials: true,
    });

  const findDocuments = (request: FindAllDocumentsRequest) =>
    axios.post<FindAllDocumentsResponse>(`${config.API_URL}${BASE_PATH}/documents`, request, {
      withCredentials: true,
    });

  const getFilters = (params: GetFilterQueryParams) =>
    axios.get<GetFilterResponse>(`${config.API_URL}${BASE_PATH}/filter`, {
      params,
      withCredentials: true,
    });

  const getPdfUrl = (pdfLink: string) => {
    return `${config.API_URL}${BASE_PATH}/document/download?pdfLink=${pdfLink}`;
  };

  const downloadBatch = (pdfLink: string) => {
    return axios.get(`${config.API_URL}${BASE_PATH}/document/download?pdfLink=${pdfLink}`, {
      withCredentials: true,
      responseType: 'arraybuffer',
    });
  };

  const handleDocumentAction = ({ actionType, ...requestData }: DocumentActionRequest) =>
    axios.post<DocumentActionResponse>(`${config.API_URL}${BASE_PATH}/document/action/${actionType}`, requestData, {
      withCredentials: true,
    });

  const handleBatchAction = ({ actionType, ...requestData }: BatchesActionRequest) =>
    axios.post<BatchesActionResponse>(`${config.API_URL}${BASE_PATH}/batches/action/${actionType}`, requestData, {
      withCredentials: true,
    });

  const getRejectionReasons = (request: GetReasonsRequest) =>
    axios.post<Reason[]>(`${config.API_URL}${BASE_PATH}/reasons`, request, {
      withCredentials: true,
    });

  const setFixDate = (request: SetFixedDateRequest) =>
    axios.post<SetFixedDateResponse>(`${config.API_URL}${BASE_PATH}/batches/fixedDate`, request, {
      withCredentials: true,
    });

  return {
    findBatches,
    findDocuments,
    getFilters,
    getPdfUrl,
    downloadBatch,
    handleDocumentAction,
    handleBatchAction,
    getRejectionReasons,
    setFixDate,
  };
}

export const useApprovalService = () => useService(createApprovalService);
