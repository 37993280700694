import { Button, Divider, Empty, Form, FormInstance, Input, Radio, Select, Space } from 'antd';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce, useList } from 'react-use';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { translationNamespace } from '../../constants/translation-resources';
import { Preset, PresetType } from '../../models/preset';
import { Feature } from '../../../shared/models/features';
import { redundantSpacebarsRule } from '../../../shared/utils/form-rules';
import { PresetTypes } from '../../constants/preset';
import { useFindDefinitionsQuery, useGetDefinitionQuery } from '../../../shared/data/queries/definition-queries';

export enum PresetUpsertFormField {
  NAME = 'name',
  DEFINITION = 'definition',
  REPORT_COLUMNS = 'reportColumns',
  FILTERS = 'filters',
  FILTER_COLUMN = 'column',
  FILTER_VALUE = 'value',
  SEARCH = 'search',
}

interface Filter {
  [PresetUpsertFormField.FILTER_COLUMN]: string;
  [PresetUpsertFormField.FILTER_VALUE]: unknown;
}

export interface PresetUpsertFormValues {
  [PresetUpsertFormField.NAME]: string;
  [PresetUpsertFormField.DEFINITION]: number;
  [PresetUpsertFormField.REPORT_COLUMNS]: string[];
  [PresetUpsertFormField.FILTERS]: Filter[];
  [PresetUpsertFormField.SEARCH]: string;
}

interface Props {
  form: FormInstance<PresetUpsertFormValues>;
  onSubmit?: (values: PresetUpsertFormValues) => void;
  preset?: Preset;
}

export const PresetUpsertForm: FC<Props> = ({ form, onSubmit, preset }) => {
  const { t } = useTranslation(translationNamespace);
  const [definitionsSearchValue, setDefinitionsSearchValue] = useState<string>();
  const [definitionsDebouncedSearchValue, setDefinitionsDebouncedSearchValue] = useState<string>();
  const { data: definitionsData, isLoading: definitionsLoading } = useFindDefinitionsQuery({
    page: 1,
    pageSize: 25,
    searchValue: definitionsDebouncedSearchValue,
    filters: preset === undefined ? undefined : { id: [preset.definitionId] },
    attributes: ['id', 'name', 'status'],
  });
  const [selectedDefinitionId, setSelectedDefinitionId] = useState<number>();
  const { data: selectedDefinitionRes, isLoading: selectedDefinitionLoading } =
    useGetDefinitionQuery(selectedDefinitionId);
  const [presetType, setPresetType] = useState<PresetType>();
  const [selectedReportColumns, { set: setSelectedReportColumns, reset: resetSelectedReportColumns }] = useList<string>(
    []
  );
  const [selectedFilterColumns, { set: setSelectedFilterColumns }] = useList<string>();

  const definitionSelectOptions = useMemo(
    () =>
      definitionsData?.resources.map((definition, index) => (
        <Select.Option disabled={definition.status === 'INVALID'} key={index} value={definition.id}>
          {definition.name}
        </Select.Option>
      )) ?? [],
    [definitionsData?.resources]
  );

  const columnSelectOptions = useMemo(() => {
    return (
      selectedDefinitionRes?.columnTypes.map(({ name }, index) => (
        <Select.Option key={index} value={name}>
          {name}
        </Select.Option>
      )) ?? []
    );
  }, [selectedDefinitionRes]);

  const filterColumnSelectOptions = useMemo(() => {
    return selectedReportColumns.length > 0
      ? selectedReportColumns
          .filter(reportColumn => !selectedFilterColumns?.includes(reportColumn))
          .map((column, index) => (
            <Select.Option key={index} value={column}>
              {column}
            </Select.Option>
          ))
      : [];
  }, [selectedFilterColumns, selectedReportColumns]);

  const selectDefinition = (definitionId: number) => {
    resetSelectedReportColumns();
    setSelectedDefinitionId(definitionId);
  };

  const selectReportColumns = (columns: string[]) => {
    setSelectedReportColumns(columns);
  };

  const applyDefinitionsSearch = (value: string) => {
    setDefinitionsSearchValue(value);
  };

  const refreshSelectedFilterColumns = useCallback(() => {
    setSelectedFilterColumns(
      form.getFieldValue(PresetUpsertFormField.FILTERS)?.map(filter => filter?.[PresetUpsertFormField.FILTER_COLUMN])
    );
  }, [form, setSelectedFilterColumns]);

  useDebounce(
    () => {
      setDefinitionsDebouncedSearchValue(definitionsSearchValue);
    },
    400,
    [definitionsSearchValue]
  );

  useEffect(() => {
    if (preset) {
      setSelectedDefinitionId(preset.definitionId);
      setSelectedReportColumns(preset.query.attributes);
      setPresetType(
        preset.query.search != null ? PresetTypes.SEARCH : preset.query.filters != null ? PresetTypes.FILTERS : null
      );
    }
  }, [form, preset, setSelectedReportColumns]);

  useEffect(() => {
    form.setFieldsValue({ [PresetUpsertFormField.DEFINITION]: selectedDefinitionId });
  }, [selectedDefinitionId, form]);

  useEffect(() => {
    form.setFieldsValue({ [PresetUpsertFormField.REPORT_COLUMNS]: selectedReportColumns });
  }, [selectedReportColumns, form]);

  useEffect(() => {
    refreshSelectedFilterColumns();
  }, [presetType, refreshSelectedFilterColumns]);

  useEffect(() => {
    const currentValues = form.getFieldsValue();
    form.setFieldsValue({
      [PresetUpsertFormField.FILTERS]: currentValues[PresetUpsertFormField.FILTERS]?.filter(filter =>
        filter?.[PresetUpsertFormField.FILTER_COLUMN] != null
          ? selectedReportColumns.includes(filter[PresetUpsertFormField.FILTER_COLUMN])
          : false
      ),
    });
  }, [form, selectedReportColumns]);

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      layout="horizontal"
      labelCol={{ lg: 4, xl: 4 }}
      wrapperCol={{ lg: 16, xl: 10 }}
      requiredMark={false}
    >
      <Form.Item
        label={t(`${Feature.SHARED}:name`)}
        name={PresetUpsertFormField.NAME}
        rules={[{ required: true, max: 180 }, redundantSpacebarsRule(t)]}
      >
        <Input placeholder={t('presets.upsert.name.placeholder')} />
      </Form.Item>

      <Form.Item
        label={t(`${Feature.SHARED}:definition.label`)}
        name={PresetUpsertFormField.DEFINITION}
        rules={[{ required: true }]}
      >
        <Select
          allowClear
          showSearch
          filterOption={false}
          placeholder={t('presets.upsert.select')}
          loading={definitionsLoading}
          onSearch={applyDefinitionsSearch}
          onSelect={selectDefinition}
          disabled={preset != null}
        >
          {definitionSelectOptions}
        </Select>
      </Form.Item>

      <Form.Item
        label={t('presets.upsert.reportColumns.label')}
        name={PresetUpsertFormField.REPORT_COLUMNS}
        rules={[{ required: true }]}
      >
        <Select
          placeholder={t('presets.upsert.select')}
          showSearch
          allowClear
          mode="multiple"
          loading={selectedDefinitionLoading}
          notFoundContent={<Empty description={t('presets.upsert.reportColumns.noDefinition')} />}
          onChange={selectReportColumns}
        >
          {columnSelectOptions}
        </Select>
      </Form.Item>

      <Form.Item label={t(`${Feature.SHARED}:type`)}>
        <Radio.Group value={presetType} onChange={value => setPresetType(value.target.value)}>
          {Object.values(PresetTypes).map((type, index) => (
            <Radio key={index} value={type}>
              {t(`${Feature.SHARED}:${type}`)}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      {presetType === PresetTypes.SEARCH && (
        <Form.Item name={PresetUpsertFormField.SEARCH} label={t(`${Feature.SHARED}:search`)} css={marginBottomStyles}>
          <Input placeholder={t(`${Feature.SHARED}:report.placeholder`)} />
        </Form.Item>
      )}

      {presetType === PresetTypes.FILTERS && (
        <>
          <Divider orientation="left">{t(`${Feature.SHARED}:filters`)}</Divider>

          <Form.List name={PresetUpsertFormField.FILTERS}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(field => (
                  <Form.Item key={field.key} label={t('presets.upsert.filter.column.label')} css={noMarginBottomStyles}>
                    <StyledSpace align="baseline">
                      <Form.Item
                        {...field}
                        name={[field.name, PresetUpsertFormField.FILTER_COLUMN]}
                        messageVariables={{ label: t('presets.upsert.filter.column.label') }}
                        rules={[{ required: true }]}
                      >
                        <Select
                          placeholder={t('presets.upsert.select')}
                          allowClear
                          onChange={refreshSelectedFilterColumns}
                        >
                          {filterColumnSelectOptions}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        {...field}
                        name={[field.name, PresetUpsertFormField.FILTER_VALUE]}
                        rules={[{ required: true }]}
                        label={t(`${Feature.SHARED}:value`)}
                      >
                        <Select size="middle" mode="tags" open={false} />
                      </Form.Item>

                      <MinusCircleOutlined
                        onClick={() => {
                          remove(field.name);
                          refreshSelectedFilterColumns();
                        }}
                      />
                    </StyledSpace>
                  </Form.Item>
                ))}
                <Form.Item>
                  <StyledButton
                    disabled={fields.length >= selectedReportColumns.length}
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    {t('presets.upsert.addFilter')}
                  </StyledButton>
                </Form.Item>
              </>
            )}
          </Form.List>
        </>
      )}
    </Form>
  );
};

const marginBottomStyles = css`
  margin-bottom: 52px;
`;

const noMarginBottomStyles = css`
  margin: 0;
`;

const StyledSpace = styled(Space)`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  > div:not(:last-of-type) {
    width: 100%;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 180px;
`;
