import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { FindAllBatchesRequest } from '@pccr-ifc/pccr-ifc';

import { FindBatchesQueryParams, GetReasonsRequest } from '../../models/batch';
import { getResourceListQueryKeyRoot } from '../../../shared/utils/resource-query-key';
import { convertGetReasonsResponse, convertSearchBatchesResponse } from '../converters/batch-converters';
import { useApprovalService } from '../services/use-approval-service';
import { displayErrors } from '../../../shared/utils/error';
import { DocumentsSearchResult, Reason } from '../../models/api/response';

export enum BatchQueryKey {
  FILTER = 'filter',
  REASONS = 'reasons',
}

export function useFindBatchesQuery({
  page,
  pageSize,
  sortBy,
  sortDirection,
  search,
  groupId,
  filter,
}: FindBatchesQueryParams) {
  const documentService = useApprovalService();
  const { t } = useTranslation();

  return useQuery<DocumentsSearchResult, unknown>(
    [getResourceListQueryKeyRoot('BATCH'), page, pageSize, sortBy, sortDirection, search, groupId, filter],
    async () => {
      const request: FindAllBatchesRequest = {
        currentPage: page,
        limitOnPage: pageSize,
        sortBy,
        sortDirection,
        search,
        groupId,
        filter,
      };

      const response = await documentService.findBatches(request);
      return convertSearchBatchesResponse(response.data);
    },
    {
      onError: error => displayErrors(error, t),
    }
  );
}

export function useGetFiltersQuery(groupId: number, enabled: boolean) {
  const documentService = useApprovalService();
  const { t } = useTranslation();

  return useQuery(
    [BatchQueryKey.FILTER, groupId],
    async () => {
      const response = await documentService.getFilters({ groupId });
      return response.data;
    },
    {
      enabled,
      onError: error => displayErrors(error, t),
    }
  );
}

export function useGetReasonsQuery(request: GetReasonsRequest, enabled: boolean) {
  const documentService = useApprovalService();
  const { t } = useTranslation();

  return useQuery<Reason[], unknown>(
    [BatchQueryKey.REASONS, request.lang],
    async () => {
      const response = await documentService.getRejectionReasons(request);
      return convertGetReasonsResponse(response.data);
    },
    {
      enabled,
      onError: error => displayErrors(error, t),
    }
  );
}
