import { Button, Card, Col, Form, message, Row, Space } from 'antd';
import { FC, useState } from 'react';
import { useDebounce, useToggle } from 'react-use';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { PolicyTemplate } from '@pccr-ifc/pccr-ifc';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';

import { useTableOptions } from '../../../shared/hooks/use-data-options';
import { Feature } from '../../../shared/models/features';
import { Policy } from '../../../shared/models/policy';
import { PoliciesTable, PoliciesTableFilters, PoliciesTableFiltersForm } from '../../components/policies-table';
import { translationNamespace } from '../../constants/translation-resources';
import { useFindPoliciesQuery } from '../../data/queries/policy-queries';
import { PoliciesFilters } from '../../models/policy';
import { convertTableFiltersToFilters, convertValuesToNewPolicy } from '../../data/converters/policy-converters';
import { Orders } from '../../../shared/constants/order';
import { CustomSearch } from '../../../shared/components/search';
import { CustomFilterButton } from '../../../shared/components/filter-button';
import { GeneratePolicyModal } from '../../components/generate-policy-modal';
import { useCreatePolicyMutation } from '../../data/mutations/policy-mutations';
import { GeneratePolicyFormValues } from '../../components/generate-policy-modal/generate-policy-modal';
import { colors } from '../../../shared/constants/styles';
import { permissionSelector } from '../../../shared/data/store/selectors/auth-selectors';

export const PoliciesListView: FC = () => {
  const { t } = useTranslation(translationNamespace);
  const [filtersForm] = Form.useForm<PoliciesTableFilters>();
  const { page, setPage, pageSize, order, handleTableChange } = useTableOptions<Policy>([['createdAt', Orders.DESC]]);
  const [showFilters, toggleShowFilters] = useToggle(false);
  const [filters, setFilters] = useState<PoliciesFilters>(null);
  const [searchValue, setSearchValue] = useState<string>();
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>();
  const [showCreatePolicyModal, toggleCreatePolicyModal] = useToggle(false);
  const { data, isLoading } = useFindPoliciesQuery({
    page,
    pageSize,
    searchValue: debouncedSearchValue,
    filters,
    order,
  });
  const { url } = useRouteMatch();
  const mutation = useCreatePolicyMutation();
  const permissions = useSelector(permissionSelector);

  const submit = (values: GeneratePolicyFormValues, template: PolicyTemplate) => {
    const newPolicy = convertValuesToNewPolicy(values, template);

    mutation.mutate(newPolicy, {
      onSuccess: () => {
        message.success(t('policies.shared.createSuccessMessage'));
        toggleCreatePolicyModal();
      },
    });
  };

  const applyFilters = (filters: PoliciesTableFilters) => {
    const policiesFilters = convertTableFiltersToFilters(filters);

    setSearchValue(null);
    setFilters(policiesFilters);
    setPage(1);
  };

  const applySearch = (value: string) => {
    setFilters(null);
    filtersForm.resetFields();
    setSearchValue(value);
    setPage(1);
  };

  useDebounce(
    () => {
      if (searchValue != null) {
        setFilters(null);
        filtersForm.resetFields();
        setPage(1);
      }
      setDebouncedSearchValue(searchValue);
    },
    400,
    [searchValue]
  );

  return (
    <Row>
      <StyledSpace>
        <CustomSearch
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder={t(`${Feature.SHARED}:search`)}
          onSearch={applySearch}
          allowClear
          size="large"
        />
        <CustomFilterButton type="link" onClick={toggleShowFilters}>
          {t(`${Feature.SHARED}:filters`)} <StyledDownOutlined rotate={showFilters ? 180 : 0} />
        </CustomFilterButton>
      </StyledSpace>
      {permissions.POLICY.CREATE && (
        <ButtonsSpace>
          <StyledButton type="primary" size="large" onClick={toggleCreatePolicyModal}>
            {t('policies.list.generateNew')}
          </StyledButton>
          <Link to={`${url}/new`}>
            <StyledButton type="primary" size="large">
              {t('policies.list.new')}
            </StyledButton>
          </Link>
        </ButtonsSpace>
      )}
      {showFilters && (
        <Col span={24} css={marginTop}>
          <Card title={t(`${Feature.SHARED}:filters`)}>
            <PoliciesTableFiltersForm form={filtersForm} onApply={applyFilters} />
          </Card>
        </Col>
      )}
      <Col span={24} style={{ marginTop: showFilters || isLoading || data?.total === 0 ? '50px' : '0' }}>
        <PoliciesTable
          dataSource={data?.resources}
          pagination={{
            total: data?.total,
            current: page,
            pageSize,
            showSizeChanger: data?.total > 10,
            position: ['topRight'],
          }}
          loading={isLoading}
          onChange={handleTableChange}
          scroll={{ x: 1100 }}
        />
      </Col>
      <GeneratePolicyModal open={showCreatePolicyModal} onSubmit={submit} onCancel={toggleCreatePolicyModal} />
    </Row>
  );
};

const StyledDownOutlined = styled(DownOutlined)`
  > svg {
    transition: transform 0.2s;
  }
`;

const StyledSpace = styled(Space)`
  position: absolute;
  z-index: 1;

  .ant-space-item {
    height: 38px;
  }
`;

const StyledButton = styled(Button)`
  background-color: ${colors.orange};

  &:hover {
    background-color: ${colors.hoverOrange} !important;
  }
`;

const ButtonsSpace = styled(Space)`
  position: fixed;
  z-index: 1;
  top: 71px;
  right: 24px;
`;

const marginTop = css`
  margin-top: 50px;
`;
