import { Button, TableColumnType } from 'antd';
import { TFunction } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';

import { Feature } from '../../../shared/models/features';
import { Batch } from '../../models/batch';
import { Order } from '../../../shared/models/order';
import { convertOrderToAntdOrder } from '../../../shared/utils/converters';
import { formatISODate } from '../../../shared/utils/date';
import { Language } from '../../../shared/models/language';

export const getColumns = (
  t: TFunction<Feature.APPROVAL>,
  language: Language,
  defaultOrder: [keyof Batch, Order],
  openPdf: (fileName: string) => void
): TableColumnType<Batch>[] => {
  const columns = [
    {
      title: t('batches.fileName'),
      dataIndex: 'fileName',
      sorter: true,
    },
    {
      title: t('batches.docs'),
      dataIndex: 'documents',
      sorter: true,
    },
    {
      title: t('shared.pages'),
      dataIndex: 'pages',
      sorter: true,
    },
    {
      title: t(`${Feature.SHARED}:status`),
      dataIndex: 'status',
      sorter: false,
    },
    {
      title: t('batches.fixDate.label'),
      dataIndex: 'fixDate',
      render: (fixDate: Batch['fixDate']) => formatISODate(fixDate, language)?.slice(0, 10),
      sorter: true,
    },
    {
      title: t('batches.tenant'),
      dataIndex: 'tenant',
      sorter: true,
    },
    {
      title: t('batches.splitFrm'),
      dataIndex: 'splitFrm',
      sorter: true,
    },
    {
      title: t('batches.application'),
      dataIndex: 'application',
      sorter: true,
    },
    {
      title: t('batches.applicationGroup'),
      dataIndex: 'applicationGroup',
      sorter: true,
    },
    {
      title: t('batches.dataEntry'),
      dataIndex: 'dataEntry',
      sorter: true,
    },
    {
      title: t('batches.remainingDays'),
      dataIndex: 'remainingDays',
      sorter: true,
    },
    {
      dataIndex: 'pdfLink',
      render: (pdfLink: Batch['pdfLink']) => (
        <Button type="primary" icon={<DownloadOutlined />} onClick={() => openPdf(pdfLink)}>
          {t(`${Feature.SHARED}:view`)}
        </Button>
      ),
      width: 100,
    },
  ];

  columns.forEach(column => {
    if (column.dataIndex === defaultOrder[0]) {
      column['defaultSortOrder'] = convertOrderToAntdOrder(defaultOrder[1]);
    }
  });

  return columns;
};
